<template>
  <Header title="Jurnal Umum" link1="Akuntansi" link2="Jurnal Umum"></Header>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-8">
              <div class="input-group mb-3">
                <input type="file" class="form-control" id="file" ref="file" v-on:change="handleFileUpload()">
                <button class="btn btn-outline-primary" type="button" v-on:click="importJournals()"> <ion-icon name="cloud-upload-outline"></ion-icon> Impor Jurnal</button>
              </div>
            </div>
            <div class="col-md-4">
              <div class="float-end">
                <button class="btn btn-circle btn-gradient" @click="add()"> <ion-icon name="add-circle-outline"></ion-icon> Tambah Jurnal Umum</button>
              </div>
            </div>
          </div>
          <div class="row p-2">
            <div class="col-md-3">
              <label for="">Kode Akun :</label>
              <input placeholder="No Akun" v-model="param.acc_account_id" class="text-primary form-select" type="text" autocomplete="off" @input="getSuggestions(param.acc_account_id, 'search')" @focus="modal = true">
              <div v-if="accounts.length > 0 && autocomplete == 'search' && param.acc_account_id.length > 3">
                <ul class="container-autocomplete">
                    <li class="item-autocomplete" v-for="account in accounts" :key="account.id" @click="setAccountID(account)">{{account.id}} - {{ account.name }}</li>
                </ul>
              </div>
            </div>
            <div class="col-md-3">
              <label for="">Dari Tanggal :</label>
              <datepicker class="form-control" v-model="param.date_from" />
            </div>
            <div class="col-md-3">
              <label for="">Sampai Tanggal :</label>
              <datepicker class="form-control" v-model="param.date_to" />
            </div>
            <div class="col-md-3">
              <label for=""></label>
              <div class="align-middle">
                <button type="button" class="btn btn-primary mx-1" href="javascript:void(0)" @click="loadData('refresh')" ><ion-icon name="search-outline"></ion-icon> Cari</button>

                <button type="button" class="btn btn-outline-primary mx-1" @click="exportJournals()"><ion-icon name="cloud-download-outline"></ion-icon> Export</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-6">
              <div class="d-inline">
                <label for="">Tampilkan :</label>
                <select @change="loadData('refresh')" v-model="param.limit" class="d-inline text-primary form-select form-select-noborder" >
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="float-end">
                <v-pagination
                  v-model="currentPage"
                  :pages="totalPage"
                  :range-size="1"
                  @update:modelValue="onPageChanged" />
              </div>
            </div>
          </div>
          <table class="table table-borderless table-data fw-bold">
            <thead>
              <tr>
                <th>No</th>
                <th>Reff</th>
                <th>Tanggal</th>
                <th>Memo</th>
                <th>Total</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(journal, index) in journals" :key="index">
                <td>{{ index+1 }}</td>
                <td>{{ journal.reff_no }}</td>
                <td>{{ beautifyDate(journal.datetime) }}</td>
                <td>{{ journal.description }}</td>
                <td>{{ toLocaleString(journal.total_debit) }}</td>
                <td>
                  <a class="btn btn-sm btn-link-gray" href="#" @click="edit(journal.id)">Edit <ion-icon name="create-outline"></ion-icon></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div id="journalModal" class="modal" tabindex="-1">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" v-if="state == 'add'">Tambah Jurnal Umum</h5>
          <h5 class="modal-title" v-if="state == 'edit'">Ubah Jurnal Umum</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" v-if="!isInputJournal">
          <form class="row g-3">
            <div class="col-md-6">
              <label for="" class="form-label">Tipe Transaksi <span class="text-danger">*</span> </label>
              <select class="form-select" v-model="selectedType">
                <option value=""></option>
                <option
                  v-for="(type, key) in journalMap2Types"
                  :key="type.id"
                  :label="type.name"
                  :value="type.id">
                </option>
                <option value="other">other</option>
              </select>
            </div>
            <div class="col-md-6" v-if="selectedType">
              <label for="" class="form-label"> &nbsp; </label>
              <select class="form-select" v-model="selectedMap" >
                <option value=""></option>
                <option
                  v-for="(map, key) in journalMaps2[selectedType]"
                  :key="map.id"
                  :label="map.name"
                  :value="map">
                </option>
              </select>
            </div>
            <div class="col-md-6" v-if="selectedMap">
              <label for="" class="form-label"> Nominal <span class="text-danger">*</span> </label>
              <input type="number" class="form-control" v-model="transaction.amount">
            </div>
            <div class="col-md-6" v-if="selectedType">
              <label for="" class="form-label"> &nbsp; </label>
              <button type="button" class="form-control btn btn-primary" @click="addTransaction(transaction)"> <ion-icon name="add-circle-outline"></ion-icon> Buat Transaksi</button>
            </div>
          </form>
        </div>
        <div class="modal-body" v-if="isInputJournal">
          <form class="row g-3">
            <div class="col-md-6">
              <label for="" class="form-label">No Reff <span class="text-danger">*</span> </label>
              <input type="reff_no" v-model="data.reff_no" class="form-control">
            </div>
            <div class="col-md-6">
              <label for="" class="form-label">Tgl Transaksi</label>
              <datepicker class="form-control" v-model="data.datetime" style="display:inherit;width:auto;"/>
            </div>
            <div class="col-12">
              <table class="table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Akun</th>
                    <th>Debit</th>
                    <th>Kredit</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(detail, i) in data.details" :key="i">
                    <td>{{ i+1 }}</td>
                    <td>
                      <input type="text" v-model="detail.acc_account_id" class="form-control" readonly>
                      <input type="text" v-model="detail.acc_account_name" class="form-control" readonly>
                    </td>
                    <td><CurrencyInput v-model="detail.debit" :options="config" @change="calcDebitCredit()" /></td>
                    <td><CurrencyInput v-model="detail.credit" :options="config" @change="calcDebitCredit()" /></td>
                    <td><button v-if="!detail.id" type="button" class="btn btn-icon" @click="removeDetail(i)"> <ion-icon name="trash"></ion-icon> </button> </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      <input placeholder="No Akun" v-model="keyword" class="text-primary form-select" type="text" autocomplete="off" @input="getSuggestions(keyword, 'modal')" @focus="modal = true">
                      <div v-if="accounts.length > 0 && autocomplete == 'modal' && keyword.length > 3">
                        <ul class="container-autocomplete">
                            <li class="item-autocomplete" v-for="account in accounts" :key="account.id" @click="setSearch(account)">{{account.id}} - {{ account.name }}</li>
                        </ul>
                      </div>
                    </td>
                    <td><CurrencyInput v-model="detailData.debit" :options="config" @change="calcDebitCredit()" /></td>
                    <td><CurrencyInput v-model="detailData.credit" :options="config" @change="calcDebitCredit()" /></td>
                    <td><button type="button" class="btn btn-outline-secondary" @click="addDetail()"> <ion-icon name="add"></ion-icon></button> </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-8">
              <label class="form-label">Memo</label>
              <textarea class="form-control" v-model="data.description" name="description" cols="30" rows="3"></textarea>
            </div>
            <div class="col-md-4">
              <table class="table">
                <tbody>
                  <tr><th>Total Debit</th><th>{{ toLocaleString(data.total_debit) }}</th></tr>
                  <tr><th>Total Kredit</th><th>{{ toLocaleString(data.total_credit) }}</th></tr>
                  <tr><th class="text-warning">Tidak Balance</th><th class="text-warning">{{ toLocaleString(data.total_debit - data.total_credit) }}</th></tr>
                </tbody>
              </table>
            </div>
          </form>
        </div>
        <div class="modal-footer" v-if="isInputJournal">
          <button type="button" class="btn btn-primary" @click="save()"> <ion-icon name="save-outline"></ion-icon> Simpan</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script language="ts" src="./scripts/journal.ts"></script>
