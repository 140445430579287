import {
  Vue, Options
} from 'vue-class-component'
import * as bootstrap from 'bootstrap'
import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'
import CurrencyInput from '@/components/CurrencyInput.vue'
import Datepicker from 'vue3-datepicker'
import swal from 'sweetalert'

import Header from '@/components/Header.vue'
import { journalFactory } from '@/http/journal'
import { accountFactory } from '@/http/account'
import { staticFactory } from '@/http/static'
import Utils from '@/utils/utils'
@Options({
  components: {
    Header,
    Datepicker,
    CurrencyInput,
    VPagination
  },
  data() {
    return {
      journals: [],
      accounts: [],
      journalMaps2: [],
      journalMap2Types: [],
      selectedType: null,
      selectedMap: null,
      transaction: {
        journal_map2_id: null,
        amount: 0
      },
      isInputJournal: false,
      totalJournal: 0,
      file: '',
      param: {
        acc_account_id: null,
        date_from: null,
        date_to: null,
        keyword: "",
        offset: 0,
        limit: 20,
        order_by: "desc",
        order_at: "created_at",
      },
      data: {
        id: null,
        reff_no: '',
        total_debit: 0,
        total_credit: 0,
        datetime: null,
        description: '',
        details: []
      },
      detailData: {
        acc_account_id: 0,
        acc_account_name: '',
        debit: 0,
        credit: 0
      },
      keyword: '',
      currentPage: 1,
      totalPage: 1,
      showModal: false,
      state: '',
      autocomplete: '',
    }
  },
  methods: {
    toLocaleString: function(val:any) {
      return Utils.toLocaleString(val)
    },
    beautifyDate: function (date:Date) {
      return Utils.beautifyDate(date)
    },
    onPageChanged(page:any) {
      if (page) {
        this.currentPage = page
      }
      page = Math.round(this.currentPage)
      if (this.totalPage >= page) {
        this.$router.push({ query: { page } })
      }
    },
    onPageQueryChange() {
      this.param.offset = (this.currentPage - 1) * parseInt(this.param.limit)
      this.loadData()
    },
    formatDate: function(date:any) {
      var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()
      if (month.length < 2)
        month = '0' + month
      if (day.length < 2)
        day = '0' + day
      return [year, month, day].join('-')
    },
    addTransaction: async function(transaction:any) {
      if (this.selectedType != 'other') {
        transaction.journal_map2_id = this.selectedMap.id
        this.data.description = this.selectedMap.name

        if (!transaction) {
          swal("Tentukan dulu tipe transaksi", "", "error")
          return
        }

        if (!transaction.amount) {
          swal("Wajib input nominal transaksi", "", "error")
          return
        }

        transaction.amount = parseInt(transaction.amount)

        // get journal map 2 detail
        var journal_map2:any = null;
        await Promise.all([
          journalFactory.getMap2Detail(transaction.journal_map2_id)
        ]).then(results => {
          if (results[0].data.journal_map2) {
            journal_map2 = results[0].data.journal_map2
          }
        }).catch((e) => {
          swal("Gagal load data", "", "error")
          console.log(e)
        })

        journal_map2.details.forEach((detail:any) => {
          var journal = {
            acc_account_id: detail.acc_account_id,
            acc_account_name: detail.acc_account_name,
            debit: detail.debit == 1 ? transaction.amount : 0,
            credit: detail.credit == 1 ? transaction.amount : 0
          }
          this.data.details.push(journal)
        });
      }

      this.calcDebitCredit()

      this.isInputJournal = true
      this.transaction = { journal_map2_id: null, amount: 0 }
      this.selectedTypes = null
      this.selectedType = null
    },
    loadData: function(type:any) {
      if (type === 'refresh') {
        this.onPageChanged(1)
      }

      var param = {
        acc_account_id: this.param.acc_account_id,
        date_from: "",
        date_to: "",
        offset: this.param.offset,
        limit: this.param.limit,
        order_by: "desc",
        order_at: "created_at"
      }
      if (this.param.date_from) {
        param.date_from = this.formatDate(this.param.date_from)
      }
      if (this.param.date_to) {
        param.date_to = this.formatDate(this.param.date_to)
      }

      this.totalPaid = 0
      Promise.all([
        journalFactory.get(param)
      ]).then(results => {
        this.journals = results[0].data.journals
        this.totalJournal = results[0].data.total_rows

        var limit = parseInt(this.param.limit)
        var totalPage = Math.round((this.totalJournal + limit - 1) / limit)
        if ((totalPage * limit - this.totalJournal) >= limit) {
          totalPage = Math.round(this.totalJournal / limit)
        }
        this.totalPage = totalPage

      }).catch((e) => {
        swal("Gagal load data", "", "error")
        console.log(e)
      })
    },
    async getSuggestions(keyword: string, type: string) {
      if (keyword.length >= 3) {
        Promise.resolve(accountFactory.search(keyword))
        .then(result => {
          this.accounts = result.data.accounts
          this.autocomplete = type
        }).catch((e) => {
          swal("Gagal melakukan request", "", "error")
          console.log(e)
          this.accounts = []
        })
      } else {
        this.accounts = []
      }
    },
    add: function() {
      this.isInputJournal = false;
      this.journalModal.show();
      this.data = {
        id: null,
        reff_no: '',
        total_debit: 0,
        total_credit: 0,
        datetime: null,
        description: '',
        details: []
      }
      this.state = 'add'
    },
    addDetail: function() {

      if (!this.detailData.acc_account_id) {
        swal("No akun tidak boleh kosong", "", "error")
        return
      }

      if (!this.detailData.debit && !this.detailData.credit) {
        swal("Debit atau kredit tidak boleh kosong sekaligus", "", "error")
        return
      }

      this.data.details.push(this.detailData)
      this.calcDebitCredit()
      this.detailData = {
        acc_account_id: null,
        acc_account_name: '',
        debit: 0,
        credit: 0
      }
      this.keyword = ''
    },
    removeDetail: function(index:number) {
      this.data.details.splice(index, 1)
      this.calcDebitCredit()
    },
    calcDebitCredit: function() {
      var total_credit =0
      var total_debit = 0
      this.data.details.forEach((detail:any) => {
        total_credit += parseInt(detail.credit)
        total_debit += parseInt(detail.debit)
      });
      this.data.total_credit = total_credit
      this.data.total_debit = total_debit
    },
    edit: function(journal_id: number) {
      this.isInputJournal = true;
      Promise.resolve(journalFactory.detail(journal_id))
      .then(result => {
        if (!result.data.journal) {
          swal("Jurnal tidak ditemukan", "", "error")
          return
        }

        this.data = result.data.journal
        if (this.data.datetime) {
          this.data.datetime = new Date(this.data.datetime)
        }
        this.journalModal.show()
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    save: function() {
      this.loadingSave = true

      // Validation
      if (!this.data.reff_no) {
        swal("Nomor referensi tidak boleh kosong", "", "error")
        return
      }
      if (this.data.details.length == 0) {
        swal("Rincian jurnal belum ditambahkan", "", "error")
        return
      }

      if (this.data.total_debit - this.data.total_credit != 0) {
        swal("Jurnal belum impas", "", "error")
        return
      }

      if (this.data.datetime != null) {
        this.data.datetime = this.data.datetime.toISOString().slice(0, 10);
      }

      if (this.data.id == null || this.data.id == 0) {
        Promise.resolve(journalFactory.create(this.data))
        .then(result => {
          if (result.error == false) {
            swal("Sukses menyimpan jurnal umum", "", "success")
            this.isInputJournal = false;
            this.journalModal.hide()
            this.loadData()
          } else if (result.error == true) {
            var errorMessage = result.response.data.messages
            var msg = ''
            for (let i in errorMessage) {
              msg += errorMessage[i] + "<br>"
            }
            var length = 100
            msg = msg.substring(0, length)
            swal({
              text: "Gagal Menyimpan jurnal",
              icon: "error",
              content: {
                element: "p",
                attributes: {
                  innerHTML: msg
                }
              }
            })
          }
        }).catch((e) => {
          swal("Gagal menyimpan journal", "", "error")
          console.log(e)
        }).finally(() => {
          this.loadingSave = false
        })
      } else {
        Promise.resolve(journalFactory.update(this.data.id, this.data))
        .then(result => {
          if (result.error == false) {
            swal("Sukses menyimpan jurnal umum", "", "success")
            this.isInputJournal = false;
            this.journalModal.hide()
            this.loadData()
          } else if (result.error == true) {
            var errorMessage = result.response.data.messages
            var msg = ''
            for (let i in errorMessage) {
              msg += errorMessage[i] + "<br>"
            }
            var length = 100
            msg = msg.substring(0, length)
            swal({
              text: "Gagal Menyimpan jurnal",
              icon: "error",
              content: {
                element: "p",
                attributes: {
                  innerHTML: msg
                }
              }
            })
          }
        }).catch((e) => {
          swal("Gagal menyimpan tagihan", "", "error")
          console.log(e)
        }).finally(() => {
          this.loadingSave = false
        })
      }
    },
    setAccountID(search:any){
      this.param.acc_account_id = search.id
      this.accounts = [];
    },
    setSearch(search:any){
      this.keyword = search.id
      this.detailData.acc_account_id = search.id
      this.detailData.acc_account_name = search.name
      this.accounts = [];
    },
    exportJournals: function() {
      var param = {
        acc_account_id: this.param.acc_account_id,
        date_from: null,
        date_to: null,
        order_by: "desc",
        order_at: "created_at",
      }
      if (this.param.date_from) {
        param.date_from = this.formatDate(this.param.date_from)
      }
      if (this.param.date_to) {
        param.date_to = this.formatDate(this.param.date_to)
      }
      Promise.resolve(journalFactory.export(param))
      .then(() => {

      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    importJournals: function () {
      this.imported = false

      if (this.file == '' && this.$refs.file.value != null) {
        swal("Harap pilih berkas yang akan diimpor", "", "error")
        return
      }

      let formData = new FormData();
      formData.append('excel', this.file);
      Promise.resolve(journalFactory.importJournals(formData))
        .then((response) => {
          if (!response.data) {
            swal("Gagal melakukan request", "", "error")
            return
          }

          swal("Sukses mengimpor jurnal umum", "", "success")
          this.loadData()

        }).catch((e) => {
          swal("Gagal melakukan request", "", "error")
          console.log(e)
        })
    },
    handleFileUpload: function () {
      this.file = this.$refs.file.files[0]
    }
  },
  computed: {
    config() {
      return CurrencyInput.props.options
    }
  },
  async mounted () {
    await Promise.all([
      journalFactory.getMaps2type(),
      journalFactory.getMaps2()
    ]).then(results => {
      this.journalMap2Types = results[0].data.journal_map2_types
      var tmpJournalMaps2 = results[1].data.journal_maps2
      var journalMaps2: {[k: string]: any} = {};
      tmpJournalMaps2.forEach((map:any) => {
        var type = map.acc_journal_map2_type_id
        if (type in journalMaps2 === false) {
          journalMaps2[type] = []
        }
        journalMaps2[type].push(map)
      })

      this.journalMaps2 = journalMaps2
    })

    var journalModal = document.getElementById('journalModal')
    if (journalModal != null) {
      this.journalModal = new bootstrap.Modal(journalModal)
    }
    this.journalModal.hide()

    this.loadData()
  },
})

export default class Journal extends Vue {}
